import Swiper from 'swiper'
import { Autoplay, Navigation, Pagination } from 'swiper/modules'

;(($) => {
    $(() => {
        let modules = $('.area-hero-slider')

        // Only continue if module is on page
        if (!modules.length) return

        modules.each((index, slider) => {

            const swiperClass = `.swiper_${index}`
            const swiperElement = $(slider).find(swiperClass);
            const sliderParams = JSON.parse(swiperElement.attr('data-attributes'));

            const swiper = new Swiper(swiperClass, {
                modules: [Navigation, Pagination, Autoplay],
                loop: true,
                rewind: false,
                direction: 'horizontal',
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                },
                slidesPerView: 1,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                on: {
                    afterInit: function () {
                        const slide = parseInt(sessionStorage.getItem(`swiper_${index}`)) || 0
                        this.slideTo(parseInt(slide - 1))
                    },
                },
                ...sliderParams
            });

            // Restores the slider position when editing the selected slide
            const getSliderData = (element) => {
                const slider = element.find('.swiper-slide-active').attr('data-slider')
                const slide = element.find('.swiper-slide-active').attr('data-slide')
                return {'slider': slider, 'slide': slide}
            }

            const setSliderData = (element) => {
                const {slider, slide} = getSliderData(element)
                sessionStorage.setItem(slider, slide)
            }

            swiper.on('paginationUpdate', () => setSliderData(swiperElement))
            swiper.on('slideNextTransitionStart', () => setSliderData(swiperElement))


        })
    })
})(jQuery)
