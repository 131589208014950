import cellsFactory from '../cellsFactory/cellsFactory'
import swrForm from '../partials/swrForm'
import { maxColumnsDesktop, setColumnsClasses, isMeasurementKey, changeColumnsOrder } from '../partials/columnsController'
import cartController from '../cartForm/cartController'
import getSibling from '../../../../utils/getSibling'
import { getFreshPrice, getDiscountProductPrice } from '../../../../api/variants'
import VARIANTS_TYPES from '../../config/variants-config'
export default class swrParentRow {
    constructor(props) {
        this.props = props
        this.observer = props.observer
        this.isBatchPrice = !!props.variantData.BatchPrice
        this.piecePrice = props.variantData.Price ? this.props.variantData.Price : null
        this.batchPrice = props.variantData.BatchPrice ? this.props.variantData.BatchPrice : null
        this.options = props.data.options
        this.cart = props.cart
        this.productName = props.data.productName
        this.row = document.createElement('tr')
        this.updatePrice = this.options.isCustomerUniquePriceActive
        this.priceRefreshed = false
        this.activeRow = null
        this.type = props.type
    }

    deactivateCurrentRow(parentRowToActivate, moreDetailsRowToActivate) {
        const previousActiveParentRow = this.props.table.querySelector('.swr-parent-row.active')
        const currentOpenedChildeRow = this.props.table.querySelector('.swr-child-row.open')
        const previousActiveMoreDetailsRow = this.props.table.querySelector('.more-details-row.open')
        if (previousActiveParentRow && currentOpenedChildeRow && parentRowToActivate !== previousActiveParentRow) {
            previousActiveParentRow.classList.remove('active')
            currentOpenedChildeRow.classList.remove('open')
        }
        if (previousActiveMoreDetailsRow && previousActiveMoreDetailsRow !== moreDetailsRowToActivate) {
            previousActiveMoreDetailsRow.classList.remove('open')
            previousActiveParentRow.querySelector('.icon-arrow').classList.remove('up')
        }
    }

    createSWRContent(childRowPlaceholder) {
        const swrContent = new swrForm(this.props)
        childRowPlaceholder.appendChild(swrContent.render())
        swrContent.initSelectric()
    }

    removeSWRContent() {
        const currentSwrForm = this.props.table.querySelector('.swr-form')
        if (currentSwrForm) {
            currentSwrForm.remove()
        }
    }

    handleClick(e) {
        const parentRowToActivate = e.currentTarget
        this.activeRow = parentRowToActivate
        const moreDetailsRowToActivate = getSibling.next(parentRowToActivate, '.more-details-row')
        const moreDetailsArrow = parentRowToActivate.querySelector('.icon-arrow')
        const childRowToActivate = getSibling.next(parentRowToActivate, '.swr-child-row')
        const childRowPlaceholder = childRowToActivate.firstChild.firstChild

        this.removeSWRContent()
        this.deactivateCurrentRow(parentRowToActivate, moreDetailsRowToActivate)

        if (!parentRowToActivate.classList.contains('active')) {
            moreDetailsRowToActivate.classList.add('open')
            moreDetailsArrow.classList.add('up')
            cartController.appendCart(childRowToActivate, this.cart)
        } else {
            moreDetailsRowToActivate.classList.remove('open')
            moreDetailsArrow.classList.remove('up')
            cartController.removeCart(this.cart)
        }
        parentRowToActivate.classList.toggle('active')
        childRowToActivate.classList.toggle('open')
        this.createSWRContent(childRowPlaceholder)

        if (parentRowToActivate.getAttribute('data-fresh-price') !== null) {
            this.piecePrice = parentRowToActivate.getAttribute('data-fresh-price')
        }
        this.publishActivateCart()
    }

    publishActivateCart() {
        this.observer.publish('activateCart', {
            id: this.props.variantData.id,
            RopeDiameter: this.props.variantData.RopeDiameter,
            price: this.isBatchPrice ? this.batchPrice : this.piecePrice,
            currencyCode: this.props.variantData.currencyCode,
        })
    }

    refreshPrice(variantId, partCode) {
        this.observer.publish('deactivateCart')
        if (this.options.isCustomerUniquePriceActive && !this.options.erpPrice) {
            getDiscountProductPrice(variantId)
                .then((res) => {
                    const freshPriceData = res.data
                    this.observer.publish('updatePrice', { variantId, freshPriceData, success: true, options: this.options })
                })
                .catch((err) => {
                    this.observer.publish('updatePrice', { variantId, success: false })
                    console.error(err)
                })
                .finally(() => {
                    this.priceRefreshed = true
                })
        } else if (this.options.isCustomerUniquePriceActive && this.options.erpPrice) {
            getFreshPrice(partCode)
                .then((res) => {
                    const freshPriceData = res.data
                    this.observer.publish('updatePrice', { variantId, freshPriceData, success: true, options: this.options })
                })
                .catch((err) => {
                    this.observer.publish('updatePrice', { variantId, success: false })
                    console.error(err)
                })
                .finally(() => {
                    this.priceRefreshed = true
                })
        }
    }

    render() {
        const variantId = this.props.variantData.id
        const partCode = this.props.variantData.PartCode

        this.row.className = `v-tr swr-parent-row ${this.props.isEven ? 'even' : 'odd'}`
        let columnsToRender = []
        if (this.props.variantData.id) {
            this.row.setAttribute('id', this.props.variantData.id)
        }
        this.row.appendChild(new cellsFactory(VARIANTS_TYPES.RADIO, { exclude: true }).render())
        this.props.columnsData.map((column) => {
            const cellValue = this.props.variantData[column.key]
            if (column.key === 'Stock') {
                columnsToRender.push(
                    new cellsFactory(VARIANTS_TYPES.STOCK, {
                        key: column.key,
                        variant: this.props.variantData,
                        stockDisplay: this.options.stockDisplay,
                    }).render(),
                )
            } else if (column.key === 'Price') {
                columnsToRender.push(
                    new cellsFactory(VARIANTS_TYPES.PRICE, {
                        key: column.key,
                        variant: this.props.variantData,
                        priceType: 'piece',
                        observer: this.observer,
                        updatePrice: this.updatePrice,
                    }).render(),
                )
            } else if (column.key === 'BatchPrice') {
                columnsToRender.push(
                    new cellsFactory(VARIANTS_TYPES.PRICE, {
                        key: column.key,
                        variant: this.props.variantData,
                        priceType: 'batch',
                        observer: this.observer,
                        updatePrice: this.updatePrice,
                    }).render(),
                )
            } else {
                if (!isMeasurementKey(column.key)) {
                    columnsToRender.push(new cellsFactory(VARIANTS_TYPES.DATA, { value: cellValue, key: column.key }).render())
                }
            }
        })

        if (this.options.isBlueprint) {
            columnsToRender.push(
                new cellsFactory(VARIANTS_TYPES.BLUEPRINT, {
                    variant: this.props.variantData,
                    columns: this.props.columnsData,
                    productName: this.productName,
                }).render(),
            )
        }

        const newColumnsOrder = changeColumnsOrder(columnsToRender)
        newColumnsOrder.forEach((item) => {
            this.row.appendChild(item)
        })

        setColumnsClasses(this.row)

        const moreCell = new cellsFactory(VARIANTS_TYPES.MORE, { ...this.props, parent: this.row, exclude: true }).render()
        if (this.row.childNodes.length > maxColumnsDesktop) {
            moreCell.classList.remove('hide-on-desktop')
        }

        this.row.appendChild(moreCell)
        this.row.addEventListener('click', (e) => this.handleClick(e))

        if (!this.priceRefreshed && this.updatePrice && this.type === VARIANTS_TYPES.GENERIC_VARIANTS_SWR) {
            this.refreshPrice(variantId, partCode)
        }

        this.observer.subscribe({
            event: 'updatePrice',
            action: (data) => {
                const { variantId, freshPriceData, success } = data

                if (variantId === this.props.variantData.id) {
                    if (success) {
                        // update price
                        this.piecePrice = freshPriceData.Price
                        this.row.setAttribute('data-fresh-price', freshPriceData.Price)
                    }
                    if (this.activeRow && this.activeRow.id === variantId && this.activeRow.getAttribute('data-fresh-price') !== null) {
                        this.piecePrice = this.activeRow.getAttribute('data-fresh-price')
                        this.publishActivateCart()
                    }
                }
            },
        })

        return this.row
    }
}
