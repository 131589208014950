import axios from 'axios'
import quantityInput from './quantityInput'
import CartManager from './../../../../cart/cart-manager'
import getDataAttributeValue from '../../../../attributes/attributes'
import VARIANTS_TYPES from '../../config/variants-config'

const nodeName = '.new-product-view #products_table'

export default class CartForm {
    constructor(props) {
        this.props = props
        this.observer = props.observer
        this.formElem = document.createElement('form')
        this.messageElem = document.createElement('div')
        this.addButtonElem = document.createElement('button')
        this.loginButtonElem = document.createElement('a')
        this.quantityElem = document.createElement('div')
        this.canCreateOrder = props.data.options.canCreateOrder
        this.isGuestActive = props.data.options.isGuestActive
        this.isLogged = props.data.options.isLogged
        this.isWebshopActive = props.data.options.isWebshopActive
        this.isRFQActive = props.data.options.isRFQActive
        this.hideLoginWidget = props.data.options.hideLoginWidget
        this.isSwr = props.data.options.isSwr
        this.showAddToCart = props.data.options.showAddToCart
        this.showAddToQuote = props.data.options.showAddToQuote
        this.showTotalPrice = props.data.options.showTotalPrice
        this.price = null
        this.ropeLength = 1
        this.currencyCode = ''
        this.priceTotal = null
        this.id = ''
        this.quantity = 1
        this.contactURL = getDataAttributeValue(nodeName, 'data-contact-url')
        this.actionURL = getDataAttributeValue(nodeName, 'data-action-url')
        this.loginURL = getDataAttributeValue(nodeName, 'data-login-url')
    }

    getLocaleCode() {
        return document.querySelector('html').getAttribute('data-locale').replace('_', '-')
    }

    getFormatedPrice() {
        return new Intl.NumberFormat(this.getLocaleCode(), {
            style: 'currency',
            currency: this.currencyCode,
        }).format(this.priceTotal)
    }

    isLoginPopupActive() {
        let productElem = document.querySelector('.new-product-card')
        return productElem.getAttribute('data-popup-login') || 0
    }

    updateMessage(type) {
        let tmpl = ''
        if (type === VARIANTS_TYPES.PRICE && this.showTotalPrice) {
            tmpl = `<span class='price-label'>${window.globals.trans('filter.total_price')}: </span>
            <span class='price-value'> ${this.getFormatedPrice()}</span>
            <span class='price-description'>(${window.globals.trans(
                window.globals.setting('siteName') === 'forankra-uk' ? 'filter.forankra-uk_price_alert.text' : 'filter.price_alert',
            )})
            </span>`
        } else if (type === VARIANTS_TYPES.NO_PRICE) {
            tmpl = `<span class='price-value'></span><span class='price-description'>${window.globals.trans('filter.no_price_info')}</span>`
        } else if (type === VARIANTS_TYPES.DEFAULT) {
            tmpl = `<span class='price-value'></span><span class='price-description'>${window.globals.trans('filter.cart_info')}</span>`
        }
        this.messageElem.innerHTML = tmpl
    }

    setPrice(price) {
        if (typeof price === 'string') {
            this.price = price.replaceAll(',', '.')
        } else {
            this.price = price
        }
        this.calculatePrice(this.price)
    }

    calculatePrice() {
        this.priceTotal = (this.price * this.quantity * this.ropeLength).toFixed(2)
    }

    setQuantity(qty, isFastBuyForm = false) {
        this.quantity = parseInt(qty)
        if (!isFastBuyForm) this.calculatePrice()
    }

    setId(id) {
        this.id = id
    }

    setRopeDiameter(RopeDiameter) {
        this.RopeDiameter = RopeDiameter
    }

    setRopeLength(value) {
        this.ropeLength = value
    }

    blockForm(state) {
        this.observer.publish('disableBuyButton', state)
        this.observer.publish('disableQuantityField', state)
    }

    setCurrencyCode(code) {
        this.currencyCode = code
    }

    resetInput() {
        const input = this.quantityElem.querySelector('input')
        input.value = 1
        this.setQuantity(1)
    }

    resetCart() {
        this.resetInput()
        this.setQuantity(1)
        this.setRopeLength(1)
        if (this.price) {
            this.setPrice(0)
        }
        this.updateMessage(VARIANTS_TYPES.DEFAULT)
        this.blockForm(true)
    }

    setButtonLabel(type) {
        const labels = {
            [VARIANTS_TYPES.BUY]: window.globals.trans('filter.add_to_cart'),
            [VARIANTS_TYPES.QUOTE]: window.globals.trans('filter.add_to_quote'),
        }

        this.addButtonElem.innerHTML = labels[type] || ''
    }

    setButtonState(state) {
        this.addButtonElem.disabled = state
    }

    checkSwrData(form) {
        const endingA = form.querySelector('select#rope_ending_a')
        const endingB = form.querySelector('select#rope_ending_b')
        const ropeLength = form.querySelector('input#rope_length')
        const packagingTypes = form.querySelectorAll('input[name="packing_type"]')
        const packagingTypesChecked = form.querySelectorAll('input[name="packing_type"]:checked')

        const elementsWithErrors = form.querySelectorAll('.error')
        elementsWithErrors.forEach((elem) => {
            elem.classList.remove('error')
        })

        if (endingA.value !== '' && endingB.value !== '' && ropeLength.value !== '' && packagingTypesChecked.length > 0) {
            return {
                product: this.id,
                swrDiameter: this.RopeDiameter,
                quantity: this.quantity,
                rope_ending_a: endingA.value,
                rope_length: ropeLength.value,
                rope_ending_b: endingB.value,
                packing_type: packagingTypesChecked[0].value,
            }
        } else {
            if (endingB.value === '') {
                const elem = endingB.closest('.selectric-wrapper')
                elem.classList.add('error')
            }
            if (endingA.value === '') {
                const elem = endingA.closest('.selectric-wrapper')
                elem.classList.add('error')
            }
            if (ropeLength.value === '') {
                ropeLength.classList.add('error')
            }
            if (packagingTypesChecked.length === 0) {
                packagingTypes.forEach((radio) => {
                    const label = radio.nextSibling
                    label.classList.add('error')
                })
            }
        }

        return false
    }

    sendSwr(form) {
        const data = this.checkSwrData(form)
        if (typeof data == 'object') {
            this.postData(data)
        }
    }

    sendGeneric() {
        const data = {
            quantity: this.quantity.toString(),
            product: this.id.toString(),
        }
        this.postData(data)
    }

    sendQuote(form) {
        if (form) {
            const data = this.checkSwrData(form)
            if (typeof data == 'object') {
                CartManager.addToCartSWR(data)
                return
            }
        } else {
            const quantity = this.quantity.toString()
            const variantId = this.id.toString()
            CartManager.addToCart(variantId, quantity)
        }
    }

    submitForm(e) {
        e.preventDefault()
        const form = document.querySelector('#swr_form')

        if (this.showAddToCart) {
            if (this.isSwr) {
                this.sendSwr(form)
            } else {
                this.sendGeneric(form)
            }
        } else {
            this.sendQuote(form)
        }
    }

    postData(data) {
        this.blockForm(true)
        axios({
            method: 'post',
            url: this.actionURL,
            data: data,
        })
            .then((response) => {
                const data = response.data
                CartManager.updateCoreshopCartCount(data)
                if (window.dataLayer) {
                    window.dataLayer.push({
                        event: 'addToCart',
                        ecommerce: {
                            currencyCode: data.currencyCode || '',
                            add: {
                                products: [
                                    {
                                        id: data.id.toString(),
                                        name: data.name,
                                        price: data.price || '0',
                                        category: data.category,
                                        quantity: parseInt(data.qty, 10),
                                    },
                                ],
                            },
                        },
                    })
                }
                this.blockForm(false)
            })
            .catch((err) => {
                this.blockForm(false)
                throw err
            })
    }

    render() {
        let cart = document.createElement('tr')
        cart.id = 'table_cart'
        let cart_TD = document.createElement('td')
        cart_TD.colSpan = '100'
        let cart_TABLE = document.createElement('table')
        let cart_TABLE_TR = document.createElement('tr')
        let cart_TABLE_TR_TD = document.createElement('td')
        cart_TABLE_TR_TD.colSpan = '100'

        const wrapperElem = document.createElement('div')
        wrapperElem.classList.add('wrapper')

        this.formElem.id = 'add_to_cart_form'
        this.formElem.method = 'POST'
        this.formElem.noValidate = true

        this.quantityElem.classList.add('quantity')

        const quantityInputElem = document.createElement('input')
        quantityInputElem.type = 'number'
        quantityInputElem.disabled = true

        this.quantityElem.appendChild(quantityInputElem)

        this.addButtonElem.disabled = true
        this.addButtonElem.classList.add('add-button')
        this.addButtonElem.addEventListener('click', (e) => this.submitForm(e))
        this.setButtonLabel(VARIANTS_TYPES.BUY)

        this.loginButtonElem.classList.add('login-button')
        this.loginButtonElem.href = this.loginURL
        this.loginButtonElem.innerHTML = `<p class='show-on-desktop'>${window.globals.trans(
            'filter.log_in_to_webshop',
        )}</p><p class='show-on-mobile'>${window.globals.trans('filter.log_in')}</p>`
        if (this.isLoginPopupActive()) {
            this.loginButtonElem.href = '#'
            this.loginButtonElem.setAttribute('data-toggle', 'modal')
            this.loginButtonElem.setAttribute('data-target', '#loginModal')
        }

        this.messageElem.classList.add('message')
        this.updateMessage(VARIANTS_TYPES.DEFAULT)

        wrapperElem.appendChild(this.messageElem)
        this.formElem.appendChild(this.quantityElem)
        this.formElem.appendChild(this.addButtonElem)
        if (!this.isLogged && this.isWebshopActive && !this.hideLoginWidget) {
            this.formElem.appendChild(this.loginButtonElem)
        }
        wrapperElem.appendChild(this.formElem)
        cart_TD.appendChild(wrapperElem)
        cart_TABLE_TR.appendChild(cart_TD)
        cart_TABLE.appendChild(cart_TABLE_TR)
        cart_TABLE_TR_TD.appendChild(cart_TABLE)
        cart.appendChild(cart_TABLE_TR_TD)
        this.initQuantityInput()
        this.initObservers()
        return cart
    }

    initQuantityInput() {
        quantityInput(
            this.formElem,
            {
                value: this.quantity,
            },
            this.observer,
        )
    }

    initObservers() {
        if (this.isSwr) {
            this.observer.subscribe({
                event: 'ropeLength',
                action: (value) => {
                    if (this.price) {
                        let ropeLength = parseInt(value)
                        if (isNaN(ropeLength) || ropeLength === 0) {
                            ropeLength = 1
                        }
                        this.setRopeLength(ropeLength)
                        this.calculatePrice()
                        this.updateMessage(VARIANTS_TYPES.PRICE)
                    }
                },
            })
        }

        this.observer.subscribe({
            event: 'disableBuyButton',
            action: (state) => {
                this.setButtonState(state)
            },
        })

        this.observer.subscribe({
            event: 'resetCart',
            action: () => {
                this.resetCart()
            },
        })

        this.observer.subscribe({
            event: 'cartQuantity',
            action: ({ quantity, isFastBuyForm }) => {
                this.setQuantity(quantity, isFastBuyForm)
                if (this.price && !isFastBuyForm) {
                    this.updateMessage(VARIANTS_TYPES.PRICE)
                }
            },
        })

        // refresh cart price after fetch unique price
        this.observer.subscribe({
            event: 'updatePrice',
            action: (data) => {
                const { variantId, freshPriceData, success } = data

                if (variantId === this.id && success) {
                    this.setPrice(freshPriceData.Price)
                    this.updateMessage(VARIANTS_TYPES.PRICE)
                }
            },
        })

        this.observer.subscribe({
            event: 'activateCart',
            action: (data) => {
                this.resetCart()
                this.setId(data.id)
                this.setCurrencyCode(data.currencyCode)

                if (this.isSwr) {
                    this.setRopeDiameter(data.RopeDiameter)
                }
                if (this.showAddToCart) {
                    if (data.price) {
                        this.setPrice(data.price)
                        this.updateMessage(VARIANTS_TYPES.PRICE)
                    } else {
                        this.updateMessage(VARIANTS_TYPES.NO_PRICE)
                    }
                    this.setButtonLabel(VARIANTS_TYPES.BUY)
                } else {
                    this.updateMessage(VARIANTS_TYPES.NO_PRICE)
                    this.setButtonLabel(VARIANTS_TYPES.QUOTE)
                }
                this.blockForm(false)
            },
        })
    }
}
